import { AxiosRequestConfig, Method } from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const RSwal = withReactContent(Swal);

export const Confirm = Swal.mixin({
  title: 'Confirm',
  icon: 'question',
  showCancelButton: true,
  buttonsStyling: false,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  customClass: {
    confirmButton: 'btn btn-success mx-1',
    cancelButton: 'btn btn-danger mx-1',
  },
});

export const arrayToCsv = ({
  data,
  columnDelimiter = ',',
  lineDelimiter = '\n',
  columns,
  transform,
}: {
  data: Record<string, any>[]; // Array of data
  columnDelimiter?: string; // Column separator, defaults to ","
  lineDelimiter?: string; // Line break, defaults to "\n"
  columns?: Record<string, string>; // Object with data object props as keys and the column name as values
  transform?: (data: Record<string, any>) => Record<string, any>; // function used to map data values
}): string => {
  let result, ctr, keys;

  if (!data || !data.length) {
    return '';
  }

  keys = Object.keys(columns || data[0]);

  result = '';
  result += '"' + keys.map((k) => (columns && columns[k]) || k).join(`"${columnDelimiter}"`) + '"';
  result += lineDelimiter;

  data.forEach((item) => {
    item = transform ? transform(item) : item;
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) {
        result += columnDelimiter;
      }

      result += typeof item[key] === 'string' && item[key].includes(columnDelimiter) ? `"${item[key]}"` : item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export default function buildRequest<D extends any>(
  url: string,
  method: Method = 'get',
  data?: D,
  config?: Omit<AxiosRequestConfig<D>, 'method' | 'url' | 'data'>
): AxiosRequestConfig<D> {
  return {
    method,
    url,
    data,
    ...config,
  };
}

export const roundTo = (value: number, decimals: number): number => {
  const factor = 10 ** decimals;
  return Math.round((value + Number.EPSILON) * factor) / factor; // round to 2 decimal places
};
